/* Change this file to get your personal Porfolio */

// Website related settings
const settings = {
  isSplash: false, // Change this to false if you don't want Splash screen.
};

//SEO Related settings
const seo = {
  title: "Binar's Portfolio",
  description:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  og: {
    title: "Binar Omer Portfolio",
    type: "website",
    url: "http://binaromer.dev/",
  },
};

//Home Page
const greeting = {
  title: "Binar Omer",
  logo_name: "BinaOmer",
  nickname: "Binar",
  subTitle:
    "A passionate individual who always thrives to work on end to end products which develop sustainable and scalable social and technical systems to create impact.",
  resumeLink:
    "https://drive.google.com/file/d/1dLTuGcEJ-U5x81Ql1X1QU00wGslK8EXR/view?usp=sharing",
  githubProfile: "https://github.com/binar1",
};

const socialMediaLinks = [
  {
    name: "Github",
    link: "https://github.com/binar1",
    fontAwesomeIcon: "fa-github", // Reference https://fontawesome.com/icons/github?style=brands
    backgroundColor: "#181717", // Reference https://simpleicons.org/?q=github
  },
  {
    name: "LinkedIn",
    link: "https://www.linkedin.com/in/binaromer/",
    fontAwesomeIcon: "fa-linkedin-in", // Reference https://fontawesome.com/icons/linkedin-in?style=brands
    backgroundColor: "#0077B5", // Reference https://simpleicons.org/?q=linkedin
  },
  {
    name: "YouTube",
    link: "https://www.youtube.com/channel/UCHywgWydvQN87yg3cjXTN3A",
    fontAwesomeIcon: "fa-youtube", // Reference https://fontawesome.com/icons/youtube?style=brands
    backgroundColor: "#FF0000", // Reference https://simpleicons.org/?q=youtube
  },
  {
    name: "Gmail",
    link: "mailto:binaromer96@gmail.com",
    fontAwesomeIcon: "fa-google", // Reference https://fontawesome.com/icons/google?style=brands
    backgroundColor: "#D14836", // Reference https://simpleicons.org/?q=gmail
  },
  {
    name: "Facebook",
    link: "https://www.facebook.com/binar.sulimany/",
    fontAwesomeIcon: "fa-facebook-f", // Reference https://fontawesome.com/icons/facebook-f?style=brands
    backgroundColor: "#1877F2", // Reference https://simpleicons.org/?q=facebook
  },
  {
    name: "Instagram",
    link: "https://www.instagram.com/binar.ahmed/",
    fontAwesomeIcon: "fa-instagram", // Reference https://fontawesome.com/icons/instagram?style=brands
    backgroundColor: "#E4405F", // Reference https://simpleicons.org/?q=instagram
  },
];

const skills = {
  data: [
    {
      title: "Full Stack Development",
      fileName: "FullStackImg",
      skills: [
        "⚡ Building responsive website front end using  angular || next js",
        "⚡ Developing mobile applications using, React Native and solo android apps using java",
        "⚡ Creating application backend in Node, Express,Nest,Go & Flask",
      ],
      softwareSkills: [
        {
          skillName: "HTML5",
          fontAwesomeClassname: "simple-icons:html5",
          style: {
            color: "#E34F26",
          },
        },
        {
          skillName: "CSS3",
          fontAwesomeClassname: "fa-css3",
          style: {
            color: "#1572B6",
          },
        },
        {
          skillName: "Sass",
          fontAwesomeClassname: "simple-icons:sass",
          style: {
            color: "#CC6699",
          },
        },
        {
          skillName: "JavaScript",
          fontAwesomeClassname: "simple-icons:javascript",
          style: {
            backgroundColor: "#000000",
            color: "#F7DF1E",
          },
        },
        {
          skillName: "Typescript",
          fontAwesomeClassname: "simple-icons:typescript",
          style: {
            color: "#3178C6",
          },
        }, {
          skillName: "Angular",
          fontAwesomeClassname: "simple-icons:angular",
          style: {
            color: "#DD0031",
          },
        },
        {
          skillName: "ReactJS & React Native",
          fontAwesomeClassname: "simple-icons:react",
          style: {
            color: "#61DAFB",
          },
        },
        {
          skillName: "NEXT JS",
          fontAwesomeClassname: "simple-icons:nextdotjs",
          style: {
            color: "#000",
          },
        },
        {
          skillName: "NestJS",
          fontAwesomeClassname: "simple-icons:nestjs",
          style: {
            color: "#E0234E",
          },
        },
        {
          skillName: "Python",
          fontAwesomeClassname: "simple-icons:python",
          style: {
            color: "#3776AB",
          },
        },
        {
          skillName: "NodeJS",
          fontAwesomeClassname: "simple-icons:node-dot-js",
          style: {
            color: "#339933",
          },
        },
        {
          skillName: "Java",
          fontAwesomeClassname: "simple-icons:java",
          style: {
            color: "#FF7800",
          },
        },
        {
          skillName: "Go",
          fontAwesomeClassname: "simple-icons:go",
          style: {
            color: "#00ADD8",
          },
        },
        {
          skillName: "RXJ",
          fontAwesomeClassname: "simple-icons:reactivex",
          style: {
            color: "#B7178C",
          },
        },
        {
          skillName: "Git",
          fontAwesomeClassname: "simple-icons:git",
          style: {
            color: "#F05032",
          },
        },
       
      ],
    },
    {
      title: "Cloud Infra-Architecture",
      fileName: "CloudInfraImg",
      skills: [
        "⚡ Experience working on multiple cloud platforms",
        "⚡ Hosting and maintaining websites on virtual machine instances along with integration of databases",
        "⚡ Deploying deep learning models on cloud to use on mobile devices",
        "⚡ Setting up streaming jobs from DB to Server or vice-versa on AWS",
      ],
      softwareSkills: [
        {
          skillName: "AWS",
          fontAwesomeClassname: "simple-icons:amazonaws",
          style: {
            color: "#FF9900",
          },
        },
        {
          skillName: "Firebase",
          fontAwesomeClassname: "simple-icons:firebase",
          style: {
            color: "#FFCA28",
          },
        },
        {
          skillName: "Myql",
          fontAwesomeClassname: "simple-icons:mysql",
          style: {
            color: "#336791",
          },
        },
        {
          skillName: "MongoDB",
          fontAwesomeClassname: "simple-icons:mongodb",
          style: {
            color: "#47A248",
          },
        },
        {
          skillName: "Docker",
          fontAwesomeClassname: "simple-icons:docker",
          style: {
            color: "#1488C6",
          },
        },
        {
          skillName: "Kubernetes",
          fontAwesomeClassname: "simple-icons:kubernetes",
          style: {
            color: "#326CE5",
          },
        },
      ],
    },
    
  ],
};

// Education Page
const competitiveSites = {
  competitiveSites: [
    {
      siteName: "Coursera",
      iconifyClassname: "simple-icons:coursera",
      style: {
        color: "#0056D2",
      },
      profileLink: "https://www.hackerrank.com/layman_brother",
    },
    {
      siteName: "Linux Foundation",
      iconifyClassname: "simple-icons:linuxfoundation",
      style: {
        color: "#003366",
      },
      profileLink: "https://www.codechef.com/users/ashutosh_1919",
    }
  ]
};

const degrees = {
  degrees: [
    {
      title: " Sulaimani Polytechnic University ",
      subtitle: "Bachler on Information Technology",
      logo_path: "sulaimani_polytechnic_university.jpg",
      alt_name: "SPU",
      duration: "2015 - 2019",
      descriptions: [
        "⚡ I have studied basic software engineering subjects like DS, Algorithms, DBMS, OS, CA, AI etc.",
        "⚡ Apart from this, I have done courses on Docker,Programing,Algorithms, Cloud Computing and Full Stack Development.",
      ],
    },
  ],
};

const certifications = {
  certifications: [
    {
      title: "Recoded",
      subtitle: "- Recoded bootcamp client project",
      logo_path: "ReCoded.png",
      certificate_link:
        "https://drive.google.com/file/d/0BwnctlbcaWIXdGEtRDRzbVVFdjVIWDJoZmJINDVNMXVTSVVF/view?usp=sharing&resourcekey=0-UTdfARlclEC7bf5-Om7U7w",
      alt_name: "Recoded organazation",
      color_code: "#8C151599",
    },
    {
      title: "personal",
      subtitle: "- Principle of cellular systems",
      logo_path: "cell-tower-512.webp",
      certificate_link:
        "https://drive.google.com/file/d/1WwU6PPf-N3gZkoiKi_89H4P6ZkoF4y_o/view?usp=sharing",
      alt_name: "deeplearning.ai",
      color_code: "#0C9D5899",
    },
    {
      title: "Udacity + google",
      subtitle: "- Android Basics Nanodegree By Google",
      logo_path: "udacity_google.png",
      certificate_link:
        "https://drive.google.com/file/d/1RF8pZbFg7tr2JB8X8yN3YDFSInDVa43K/view?usp=sharing",
      alt_name: "Google",
      color_code: "#00000099",
    },
    {
      title: "The Hong Kong University of Science and Technology",
      subtitle: "- Business English",
      logo_path: "coursera_logo.png",
      certificate_link:
        "https://coursera.org/share/a257cbd3b7d6723d7d6f857efbd5b8f7",
      alt_name: "IBM",
      color_code: "#1F70C199",
    }
  ]
};

// Experience Page
const experience = {
  title: "Experience",
  subtitle: "Work, Internship and Volunteership",
  description:
    "I have worked with many evolving startups as Full stack leveloper Developer and Software Architect. I have also worked with some well established companies mostly as Full stack Developer. I love organising events and that is why I am also involved with many opensource communities as a representative.",
  header_image_path: "experience.svg",
  sections: [
    {
      title: "Work",
      experiences: [
        {
          title: "Senior Full Stack Developer",
          company: "We are iQ",
          company_url: "https://iq.group/#/",
          logo_path: "iqgroup.svg",
          duration: "Sep. 2023 - PRESENT",
          location: "sulaimanyah",
          description:
            "As a Senior Full Stack Developer, I bring a proven track record in developing and managing both front-end and backend teams, with expertise in analyzing, reviewing, researching, and enhancing comprehensive technical solutions for optimal project success",
          color: "#0619aa",
        },
        {
          title: "Front-End Team Leader",
          company: "We are iQ",
          company_url: "https://iq.group/#/",
          logo_path: "iqgroup.svg",
          duration: "Nov 2020 - Sep. 2023",
          location: "sulaimanyah",
          description:
            "I am working on developing and managing front-end team ,analysing ,reviewing reasearching and improve front-end technics.",
          color: "#0879bf",
        },
        {
          title: "Full Stack Developer",
          company: "TechnoStat",
          company_url: "https://www.technostat.net",
          logo_path: "technostat.jpeg",
          duration: "Feb 2020 – Nov 2021",
          location: "Sulaymaniyah",
          description:
            "I have multiple system including website,web application and mobile application, I used deferent technologies including Angular,ASP core and React-native",
          color: "#9b1578",
        },
        {
          title: "Full Stack Developer",
          company: "Tishknet Company.",
          company_url: "https://www.tishknet.com/",
          logo_path: "tishknet.png",
          duration: "Sep 2018 – Jul 2019",
          location: "Sulaymaniyah",
          description:
            "I have developed web applications for internal company systems to improve process and managing inventory with point of sales, I Developed by using Angular ,Node js, Mongodb",
          color: "#fc1f20",
        },
      ],
    },
    {
      title: "Internships",
      experiences: [
        {
          title: "Softwrae Developer",
          company: "Halabja Group",
          company_url: "https://www.halabjagroup.com/ku/",
          logo_path: "halabja.png",
          duration: "May 2018 - Aug 2018",
          location: "Sulaimanyah, Iraq",
          description:
            "I have worked on Development  and network skills, by study and traing on Cisco and network protocols and Application development by using Nodejs and Angular ",
          color: "#ee3c26",
        }
      ],
    },
  ],
};

// Projects Page
const projectsHeader = {
  title: "Projects",
  description:
    "My projects makes use of vast variety of latest technology tools. My best experience is to create Data Science projects and deploy them to web applications using cloud infrastructure.",
  avatar_image_path: "projects_image.svg",
};



// Contact Page
const contactPageData = {
  contactSection: {
    title: "Contact Me",
    profile_image_path: "binar_omer-removebg.png",
    description:
      "I am available on almost every social media. You can message me, I will reply within 24 hours. I can help you with ML, AI, React, Android, Cloud and Opensource Development.",
  },
  addressSection: {
    title: "Address",
    subtitle:
      "Sulaymaniyah - City Center",
    avatar_image_path: "address_image.svg",
    location_map_link: "https://goo.gl/maps/YfscUrDoyi3qfTyS6",
  },
  phoneSection: {
    title: "Phone Number",
    subtitle: "+964 7717271069",
  },
};

export {
  settings,
  seo,
  greeting,
  socialMediaLinks,
  skills,
  competitiveSites,
  degrees,
  certifications,
  experience,
  projectsHeader,
  contactPageData,
};
